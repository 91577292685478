import React from "react";
import {
  Box,
  Typography,
  Link,
  Container,
  Grid,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  "&:hover": {
    color: theme.palette.secondary.light,
  },
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  "&:hover": {
    color: theme.palette.secondary.light,
  },
}));

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        padding: theme.spacing(6, 0),
      })}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Code Sultancys
            </Typography>
            <Typography variant="body2">
              Empowering businesses with innovative software solutions
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Typography variant="body2">
              <FooterLink href="#" underline="hover">
                Home
              </FooterLink>
            </Typography>
            <Typography variant="body2">
              <FooterLink href="#" underline="hover">
                Services
              </FooterLink>
            </Typography>
            <Typography variant="body2">
              <FooterLink href="#" underline="hover">
                About
              </FooterLink>
            </Typography>
            <Typography variant="body2">
              <FooterLink href="#" underline="hover">
                Contact
              </FooterLink>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Connect With Us
            </Typography>
            <SocialIcon aria-label="facebook">
              <FacebookIcon />
            </SocialIcon>
            <SocialIcon aria-label="twitter">
              <TwitterIcon />
            </SocialIcon>
            <SocialIcon aria-label="linkedin">
              <LinkedInIcon />
            </SocialIcon>
            <SocialIcon aria-label="github">
              <GitHubIcon />
            </SocialIcon>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" align="center">
            {"Copyright © "}
            <FooterLink color="inherit" href="https://codesultancys.com/">
              Code Sultancys
            </FooterLink>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
