import React from "react";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import About from "./components/About";
import Industries from "./components/Industries";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import theme from "./theme";
import "./styles/global.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <main>
        <Box id="hero">
          <Hero />
        </Box>
        <Box id="about">
          <About />
        </Box>
        <Box id="services">
          <Services />
        </Box>
        <Box id="industries">
          <Industries />
        </Box>
        <Box id="contact">
          <Contact />
        </Box>
      </main>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
