import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-scroll";
import logo from "../logo.png"; // Import the logo

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const Logo = styled("img")({
  height: 40,
  marginRight: 16,
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: "rgba(255, 215, 0, 0.1)",
  },
}));

const Header: React.FC = () => {
  return (
    <StyledAppBar position="sticky">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Logo src={logo} alt="Code Sultancys" />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "secondary.main" }}
          >
            Codesultancys
          </Typography>
          <Box>
            <Link to="hero" smooth={true} duration={500}>
              <StyledButton>Home</StyledButton>
            </Link>
            <Link to="services" smooth={true} duration={500}>
              <StyledButton>Services</StyledButton>
            </Link>
            <Link to="about" smooth={true} duration={500}>
              <StyledButton>About</StyledButton>
            </Link>
            <Link to="industries" smooth={true} duration={500}>
              <StyledButton>Industries</StyledButton>
            </Link>
            <Link to="contact" smooth={true} duration={500}>
              <StyledButton>Contact</StyledButton>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default Header;
